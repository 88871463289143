<template>
  <g :id="`FemaleAverageAvatar_${uniqueIdSuffixA}`">
    <circle style="fill: #d0d0d0" cx="14.3" cy="91.6" r="14.3" />
    <g>
      <defs>
        <ellipse
          :id="`SVGID_12345_${uniqueIdSuffixA}`"
          cx="14.3"
          cy="91.6"
          rx="12"
          ry="12.4"
        />
      </defs>
      <clipPath :id="`SVGID_6789_${uniqueIdSuffixB}`">
        <use
          :xlink:href="`#SVGID_12345_${uniqueIdSuffixA}`"
          style="overflow: visible"
        />
      </clipPath>
      <path
        :style="`clip-path: url(#SVGID_6789_${uniqueIdSuffixB}); fill: #868686;`"
        d="M21.8,98.6
			c-0.1-0.7-0.6-1.3-1.3-1.6l-2.2-0.9c0.3-0.5,0.5-1.1,0.6-1.7c0,0.3-0.1,0.5-0.1,0.5s0.5-1,0.2-1.9c0-0.2-0.1-0.5-0.1-0.7
			c-0.8-2.9,0.9-8.8-4.5-8.6c-5.4-0.2-3.7,5.7-4.5,8.6c-0.1,0.2-0.1,0.5-0.1,0.7C9.5,94,9.9,95,9.9,95s-0.1-0.3-0.1-0.7
			c0.1,0.7,0.4,1.3,0.7,1.9l-2.3,1.1c-0.8,0.4-1.3,1.1-1.5,2L6.5,103c-0.1,0.1-0.1,0.3-0.1,0.4c0,1.5,3.6,2.7,7.9,2.7
			s7.9-1.2,7.9-2.7c0-0.1,0-0.3-0.1-0.4L21.8,98.6z M18,95.5c0,0.2-0.1,0.3-0.1,0.5l0,0C17.9,95.9,18,95.7,18,95.5z M10.9,96
			L10.9,96c-0.1-0.2-0.1-0.4-0.1-0.5C10.8,95.7,10.8,95.9,10.9,96z"
      />
    </g>
  </g>
</template>
<script>
export default {
  computed: {
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
    uniqueIdSuffixB() {
      return Math.floor(Math.random() * (50 - 26) + 62);
    },
  },
};
</script>
